import React, { useEffect, useState } from "react";
import "./mobileBlock.scss";

const MobileBlock: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    isMobile ? (
      <div className="mobile-block">
        <img src="/mobileBlock.png" alt="Não disponível em dispositivos móveis" />
      </div>
    ) : null
  );
};

export default MobileBlock;
