import React, { lazy } from "react";
import ReactDOM from "react-dom/client";

import "./site/global/styles/global.scss";
import LoadingProvider from "./site/components/loading/loading";
import { Web3ContextProvider } from "./providers/Web3";
import { Web3Provider } from "./web3/Web3Provider";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Game from "./site/pages/game/game";
import MobileBlock from "./site/components/MobileBlock/MobileBlock";

const SiteBackground = lazy(() => import("./site/components/background"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <MobileBlock />
    <Web3Provider>
      <Web3ContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoadingProvider><SiteBackground /></LoadingProvider>} />
            <Route path="/play" element={<Game />} />
          </Routes>
        </Router>
      </Web3ContextProvider>
    </Web3Provider>
  </React.StrictMode >
);
