import { WagmiProvider, createConfig, http } from "wagmi";
import { base, mainnet, localhost } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { ReactNode } from "react";

const config = createConfig(
    getDefaultConfig({
        // Your dApps chains
        chains: [base],
        transports: {
            // RPC URL for each chain
            [localhost.id]: http(
                "http://localhost:8545",
            ),
            [base.id]: http(
                `https://base-rpc.publicnode.com`,
            ),
        },

        // Required API Keys
        walletConnectProjectId: "c61b7bdafc5a5fe879730de7b0dda39b",

        // Required App Info
        appName: "Batshit",

        // Optional App Info
        appDescription: "Flight To The Contraption",
        appUrl: "https://batshit.com.br/", // your app's url
        appIcon: "https://batshit.com.br/static/media/logo.bad72389ea3570d4b3b3.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    }),
);

const queryClient = new QueryClient();

export const Web3Provider = ({ children }: { children: ReactNode }) => {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <ConnectKitProvider>{children}</ConnectKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    );
};